import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`Learn Create Build Academy`]} />
    <h1>Hello everyone,</h1>
    <p>While this isn’t the easiest letter to write, Shane and I are announcing that effective immediately,
    we are shutting down Learn Create Build Academy. This closure includes all physical and virtual camps and classes.</p>
    <p>We do want to let you all know that if you have signed up for an online class your money is being refunded 100% to
    the method you used to sign up (credit card or Paypal). Please allow 5 business days for your refund to process.
    If you have not received your refund by then, please do not hesitate to contact us (see contact email below).
    </p>
    <p>We started this company because we wanted to make learning technology engaging and challenge children to think bigger.
    While we know we accomplished that, this was second to an unexpected benefit we experienced.
    Hearing from parents how we have positively impacted their children’s lives is, was, and will
    forever be the greatest accomplishment of Learn Create Build Academy. Your children have changed our lives for
    the better and for that, we are lucky.</p>
    <p>If you have any questions,
    please email us at <a href="mailto:hello@learncreatebuild.com">hello@learncreatebuild.com</a> as
    this will be the best place for us to make sure everyone receives a response.
    </p>
    <p>Thank you all again. As we have said numerous times and will continue to say...YOU ROCK. </p>
    <p>- Adam and Shane</p>
  </Layout>
)

export default IndexPage
